import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { Observable } from 'rxjs';
import { IMessage } from 'src/app/shared/models/message/message.model';
import { IMessagesSettingsLimits } from 'src/app/shared/models/organization/messages-settings.model';

export interface IMessagesGetForCustomerCardData {
  userId: number;
  orgId: number;

  page: number;
  pageSize: number;
}
export type IMessagesGetForCustomerCardResult = (IMessage & {
  senderUser: {
    name: string | null;
    surname: string | null;
    email: string | null;
  } | null;
  events: {
    id: number;
    uuid: string;
    code: string | null;
  }[];
  orders: {
    id: number;
    randomNumber: number;
    uuid: string;
  }[];
  reservations: {
    id: number;
  }[];
});

interface ICallablesBulkMessagesWhisperRecipientsData {
  orgId: number;
  query: string;
}
interface ICustomerData {
  id: number;
  name: string;
  email: string | null;
  parent: ICustomerData | null;
}
interface IShopItemData {
  id: number;
  title: string;
  code: string | null;
  locationTitle: string | null;
  startAt: Date | null;
  endAt: Date | null;
}
interface IEventData {
  id: number;
  title: string;
  startAt: Date | null;
  endAt: Date | null;
}
export interface IBulkMessagesRecipientsWhisperResult {
  customers: ICustomerData[];
  shopItems: IShopItemData[];
  events: IEventData[];
}

interface ICallablesBulkMessagesSendData {
  orgId: number;
  recipients: IBulkMessagesRecipients;
  from: string;
  subject: string;
  message: {
    html: string;
    text: string;
  };
}
interface IBulkMessagesRecipients {
  emails: string[];
  userIds: number[];
  eventIds: number[];
  shopItemIds: number[];
}

interface ICallablesBulkMessagesGetForOrgData {
  orgId: number;

  page: number;
  pageSize: number;
}

interface ICallablesBulkMessagesCanSendData {
  orgId: number;
  recipients: IBulkMessagesRecipients;
}
export interface IBulkMessagesCanSendResult {
  canSend: boolean;
  exceededLimitType: MessagesLimitType | null;
  limitExceededByCnt: number;
  alreadySentCnt: IAlreadySentMessagesCnt;
  limits: IMessagesSettingsLimits;
  recipientsCnt: number;
}
interface IAlreadySentMessagesCnt {
  day: number;
  week: number;
  month: number;
}
enum MessagesLimitType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(
    private dbService: DbService
  ) { }

  public canSendBulkMessage(data: ICallablesBulkMessagesCanSendData): Observable<IBulkMessagesCanSendResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesBulkMessagesCanSend, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getOrgBulkMessages(data: ICallablesBulkMessagesGetForOrgData): Observable<IMessage[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesBulkMessagesGetForOrg, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public sendBulkMessage(data: ICallablesBulkMessagesSendData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesBulkMessagesSend, data });
    return this.dbService.handleObs(obs);
  }

  public whisperBulkMessageRecipients(data: ICallablesBulkMessagesWhisperRecipientsData): Observable<IBulkMessagesRecipientsWhisperResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesBulkMessagesWhisperRecipients, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getForUserCard(data: IMessagesGetForCustomerCardData): Observable<IMessagesGetForCustomerCardResult[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesMessagesGetForCustomerCard, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
