import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IReservation } from 'src/app/shared/models/reservation/reservation.model';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { ItemTitlePipe } from 'src/app/shared/pipes/item-title.pipe';
import { SessionFormat } from 'src/app/shared/enums/session-group/session-formats.enum';

@Component({
  selector: 'td[app-shop-item-cell]',
  standalone: true,
  imports: [
    CommonModule,
    NgbTooltipModule,
    LocalizedDatePipe, ItemTitlePipe
  ],
  templateUrl: './shop-item-cell.component.html',
  styleUrls: ['./shop-item-cell.component.scss'],
  providers: [ LocalizedDatePipe ]
})
export class ShopItemCellComponent {
  _reservation: IReservation | undefined;
  get reservation() {
    return this._reservation;
  }
  @Input() set reservation(value: IReservation | undefined) { this._reservation = value; this.initDates(); this.initLocation(); }

  datesString: string | undefined;
  locationString: string | null = null;

  constructor(
    private router: Router,
    private navService: NavigationService,
    private localizedDate: LocalizedDatePipe
  ) {}

  navigateToEvent(eventUuid: string | undefined) {
    if (eventUuid) this.navService.goToAdminEvent({
      eventUuid,
      newTab: true
    });
  }

  navigateToShopItem(eventUuid: string | undefined, shopItemUuid: string | undefined) {
    if (eventUuid && shopItemUuid) this.navService.goToAdminShopItem({
      eventUuid,
      shopItemUuid,
      newTab: true
    });
  }

  private initLocation() {
    const r = this.reservation;

    if (!r?.shopItem?.sessionGroup?.sessions?.[0]?.location) {
      this.locationString = null;
      return;
    }

    const firstSession = r.shopItem.sessionGroup.sessions[0];

    this.locationString = firstSession.format === SessionFormat.ONLINE ? 'online' : firstSession.location?.title ?? null;
  }

  private initDates() {
    const r = this.reservation;
    if (!r) {
      this.datesString = undefined;
      return;
    }

    const firstSession = r.shopItem?.sessionGroup?.sessions?.[0];
    const lastSession = r.shopItem?.sessionGroup?.sessions?.[r.shopItem?.sessionGroup?.sessions?.length - 1];

    if (!firstSession && !lastSession) {
      this.datesString = undefined;
      return;
    }

    if (!lastSession) {
      this.datesString = this.localizedDate.transform(firstSession?.startAt, 'd.L.YY', undefined, 'UTC+0') ?? undefined;
    } else {
      const startDate = this.localizedDate.transform(firstSession?.startAt, 'd.L.', undefined, 'UTC+0') ?? '';
      const startYear = this.localizedDate.transform(firstSession?.startAt, 'YY', undefined, 'UTC+0') ?? '';
      const startTime = this.localizedDate.transform(firstSession?.startAt, 'H:mm', undefined, 'UTC+0') ?? '';
      const endDate = this.localizedDate.transform(lastSession?.endAt, 'd.L.', undefined, 'UTC+0') ?? '';
      const endYear = this.localizedDate.transform(lastSession?.endAt, 'YY', undefined, 'UTC+0') ?? '';
      if (startDate === endDate) {
        this.datesString = `${startDate}${startYear} ${startTime}`;
        return;
      } else {
        this.datesString = `${startDate} - ${endDate}${endYear}`;
      }
    }

  }

  // get eventTitleShortcut() {
  //   // match first letter of each word
  //   let title = this.reservation?.event?.title;
  //   let words = title?.split(/[^a-zA-ZáéíóúýčďěňřšťůžÁÉÍÓÚÝČĎĚŇŘŠŤŮŽ]/) ?? [];
  //   let initials = words.filter(word => word.length > 0).map(word => word[0].toUpperCase());
  //   return initials.join('');
  // }
}
