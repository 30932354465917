import { Pipe, PipeTransform } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { UtilsService } from '../services/utils.service';
import { IAddress } from '../modals/address-form-group-modal/address-form-group-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { UserNameOrEmailPipe } from './user-name-or-email.pipe';

interface IReservationUsersReservation {
  guest: boolean;
  beneficiaryUser: {
    id: number;
    email: string | null;
    name: string | null;
    surname: string | null;
  };
  partnerReservation?: {
    beneficiaryUser: {
      id: number;
      email: string | null;
      name: string | null;
      surname: string | null;
    };
    guest: boolean;
  } | null;
}

@Pipe({
  name: 'reservationUsers',
  standalone: true,
})
export class ReservationUsersPipe implements PipeTransform {

  constructor(
    public utilsService: UtilsService,
    private translateService: TranslateService,
    private userNameOrEmailPipe: UserNameOrEmailPipe
  ) {}

  transform(reservation?: IReservationUsersReservation | null): string {
    if (!reservation) return '';
    const beneficiaryUserName = this.userNameOrEmailPipe.transform(reservation.beneficiaryUser);
    if (reservation.partnerReservation?.beneficiaryUser) {
      const partnerUserName = this.userNameOrEmailPipe.transform(reservation.partnerReservation.beneficiaryUser);
      if (reservation.guest && reservation.partnerReservation.guest) {
        return this.translateService.instant('utils.reservation-users.couple-guests', { beneficiaryUserName, partnerUserName });
      } else if (reservation.guest) {
        return this.translateService.instant('utils.reservation-users.couple-indiv-guest', { beneficiaryUserName, partnerUserName });
      } else if (reservation.partnerReservation.guest) {
        return this.translateService.instant('utils.reservation-users.couple-partner-guest', { beneficiaryUserName, partnerUserName });
      } else {
        return this.translateService.instant('utils.reservation-users.couple', { beneficiaryUserName, partnerUserName });
      }
    } else {
      if (reservation.guest) {
        return this.translateService.instant('utils.reservation-users.individual-guest', { beneficiaryUserName });
      } else {
        return this.translateService.instant('utils.reservation-users.individual', { beneficiaryUserName });
      }
    }
  }
}
