

<ng-container *ngIf="state">

  <app-modal-header
    (close)="close()">

    <ng-container *ngIf="state.fetchingUser$ | async">
      <p-skeleton width="12rem" height="2rem"></p-skeleton>
      <p-skeleton width="7.5rem" height="1rem" class="mt-3 block"></p-skeleton>
    </ng-container>

    <ng-container *ngIf="(state.customerCard$ | async) as c" slot="title">
      <ng-container *ngIf="c.user">
        <p class="m-0">
          {{ c.user.name }} {{ c.user.surname }}
        </p>
      </ng-container>
    </ng-container>
  
    <ng-container *ngIf="(state.customerCard$ | async) as c" slot="actions">
      <ng-container *ngIf="c.user">
        <div (click)="onOpenCustomerPage(c.user.id)" class="small-icon-button">
          <i class="pi {{ fetchingMagicCodeUuid ? 'pi-spin pi-spinner' : 'pi-eye' }}"></i>
        </div>
      </ng-container>
    </ng-container>
  
    <ng-container *ngIf="(state.customerCard$ | async) as c">
      <ng-container *ngIf="c.user">
        <p *ngIf="userStore.hasUserRole(RoleType.SYSTEM_ADMIN, null)" class="text-xs m-0">
          ID: {{ c.user.id }} UUID: {{ c.user.uuid }}
        </p>
        <p class="m-0 text-color-secondary">
          {{ c.user.email }}
        </p>
      </ng-container>
      <ng-container *ngIf="c.credit">
        <p *ngIf="c.credit.length" class="m-0 mt-1 text-color-secondary">
          {{ 'admin.admin-customer-card-modal.credit.label' | translate }}
        </p>
        <p *ngFor="let credit of c.credit" class="m-0 -mt-1 text-color-secondary">
          {{ [credit] | price | async }}
        </p>
      </ng-container>
    </ng-container>



  </app-modal-header>
  

  <p-tabView [activeIndex]="activeTabIndex" (activeIndexChange)="onTabChange($event)" [scrollable]="true">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <span>
          {{ 'admin.admin-customer-card-modal.tab.customer.label' | translate }}
        </span>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel class="relative">
      <ng-template pTemplate="header">
        <span class="flex column-gap-1">
          {{ 'admin.admin-customer-card-modal.tab.orders.label' | translate }}
          <ng-container *ngIf="(state.customerCard$ | async) as c">
            <ng-container *ngIf="c.orders">
              <p-badge *ngIf="c.orders.length" [value]="c.orders.length.toString()" class="absolute top-0 right-0"></p-badge>
            </ng-container>
          </ng-container>
        </span>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel class="relative">
      <ng-template pTemplate="header">
        <span class="flex column-gap-1">
          {{ 'admin.admin-customer-card-modal.tab.reservations.label' | translate }}
          <ng-container *ngIf="(state.customerCard$ | async) as c">
            <ng-container *ngIf="c.reservations">
              <p-badge *ngIf="c.reservations.length" [value]="c.reservations.length.toString()" class="absolute top-0 right-0"></p-badge>
            </ng-container>
          </ng-container>
        </span>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <span>
          {{ 'admin.admin-customer-card-modal.tab.attendance.label' | translate }}
        </span>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <span>
          {{ 'admin.admin-customer-card-modal.tab.messages.label' | translate }}
        </span>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <span>
          {{ 'admin.admin-customer-card-modal.tab.notes.label' | translate }}
        </span>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
  
  <div class="modal-body flex flex-column modal-body--highlighted">
    
    <!-- CUSTOMER -->
    <ng-container *ngIf="activeTabIndex === 0">
      <ng-container *ngTemplateOutlet="customer"></ng-container>
    </ng-container>
    <!-- / -->

    <!-- ORDERS -->
    <ng-container *ngIf="activeTabIndex === 1">
      <ng-container *ngTemplateOutlet="orders"></ng-container>
    </ng-container>
    <!-- / -->

    <!-- RESERVATIONS -->
    <ng-container *ngIf="activeTabIndex === 2">
      <ng-container *ngTemplateOutlet="reservations"></ng-container>
    </ng-container>
    <!-- / -->

    <!-- ATTENDANCE -->
    <ng-container *ngIf="activeTabIndex === 3">
      <ng-container *ngTemplateOutlet="attendanceEl"></ng-container>
    </ng-container>
    <!-- / -->

    <!-- MESSAGES -->
    <ng-container *ngIf="activeTabIndex === 4">
      <ng-container *ngTemplateOutlet="messagesEl"></ng-container>
    </ng-container>
    <!-- / -->

    <!-- NOTES -->
    <ng-container *ngIf="activeTabIndex === 5">
      <ng-container *ngTemplateOutlet="notesEl"></ng-container>
    </ng-container>
    <!-- / -->
  
  </div>


  @if (footerPortal) {
    <div class="modal-footer">
      <ng-template [cdkPortalOutlet]="footerPortal"></ng-template>  
    </div>
  }
  
  
  
  
  <ng-template #customer>

    <app-customer-card-customer></app-customer-card-customer>
    
  </ng-template>





  <ng-template #orders>

    <ng-container *ngIf="(state.fetchingOrders$ | async); else notFetching">
      <div class="flex justify-content-center align-items-center">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </ng-container>

    <ng-template #notFetching>
      <ng-container *ngIf="(state.customerCard$ | async)?.orders as orders">
        
        <p *ngIf="orders.length === 0">
          {{ 'admin.admin-customer-card-modal.tab.orders.no-orders-label' | translate }}
        </p>
        
        <ng-container *ngIf="orders.length">

          <p-chips [(ngModel)]="filteredOrderNumbers" class="p-fluid mb-2 block"></p-chips>
  
          <ng-container *ngFor="let order of orders">
        
            <ng-container *ngIf="!filteredOrderNumbers.length || filteredOrderNumbers.includes(+(''+order.orderId+order.randomNumber))">
              
              <app-customer-card-order
                [order]="order"
                [collapsed]="!activeOrderNumbers.includes(+(''+order.orderId+order.randomNumber))"
                [loading]="!!(state.updatingOrders$ | async)?.includes(order.orderId)"
                class="mb-2 block">
              </app-customer-card-order>
        
            </ng-container>
        
          </ng-container>

        </ng-container>

      </ng-container>
    </ng-template>
  
  </ng-template>
  
  
  
  <ng-template #reservations>
    <p-table
      #dt
      [value]="(state.customerCard$ | async)?.reservations ?? []"
      [globalFilterFields]="[
        'event.title', 'shopItem.title',
        'partner.surname','partner.name','partner.email',
      ]"
      [loading]="!!(state.fetchingReservations$ | async)"
      [paginator]="true"
      [scrollable]="true"
      dataKey="id"
      [rows]="25"
      [rowsPerPageOptions]="[25, 50, 100, 200]"
      styleClass="p-datatable-sm">
  
      <ng-template pTemplate="caption">
        <div class="flex column-gap-2 justify-content-between">
  
          <div class="flex column-gap-2">
  
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                (input)="filterGlobal(dt, $event)"
                placeholder="{{ 'admin.admin-customer-card-modal.tab.reservations.table.search-input.placeholder' | translate }}" />
            </span>
  
            <!-- TODO filtrace podle stavu
            <p-multiSelect [ngModel]="statesFilterValue" [options]="statesFilterOptions" placeholder="Všechny stavy" (onChange)="filterByStates($event.value)" optionLabel="name">
              <ng-template let-option pTemplate="item">
                  {{ option.label }}
              </ng-template>
            </p-multiSelect>
            -->
  
          </div>
  
          <div>
            <button pButton (click)="init()" icon="pi pi-refresh" class="p-button-rounded p-button-plain p-button-text"></button>
          </div>
        </div>
      </ng-template>
  
      <ng-template pTemplate="header">
        <tr>
          <th>
            #
          </th>
          <th>
            {{ 'admin.admin-customer-card-modal.tab.reservations.table.header.item-label' | translate }}
          </th>
          <th>
            {{ 'admin.admin-customer-card-modal.tab.reservations.table.header.type-label' | translate }}
          </th>
          <th>
            {{ 'admin.admin-customer-card-modal.tab.reservations.table.header.price-label' | translate }}
          </th>
          <th>
            {{ 'admin.admin-customer-card-modal.tab.reservations.table.header.order-label' | translate }}
          </th>
          <th>
            {{ 'admin.admin-customer-card-modal.tab.reservations.table.header.state-label' | translate }}
          </th>
          <th>
  
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-reservation let-rowIndex="rowIndex">
        <tr
          app-customer-reservation-table-row
          [reservation]="reservation"
          [rowIndex]="rowIndex">
        </tr>
      </ng-template>
  
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="69">
            {{ 'admin.admin-customer-card-modal.tab.reservations.no-reservations-label' | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>

  <ng-template #attendanceEl>
    
    <app-customer-card-attendance></app-customer-card-attendance>

  </ng-template>

  <ng-template #messagesEl>

    <app-customer-card-messages [customerId]="customerId"></app-customer-card-messages>

  </ng-template>

  <ng-template #notesEl>
    
    <app-customer-card-notes [customerId]="customerId" (setFooterPortal)="footerPortal = $event"></app-customer-card-notes>

  </ng-template>

</ng-container>