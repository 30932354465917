import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { ComgateRedirectComponent } from './comgate-redirect/comgate-redirect.component';
import { InstallmentPaidComponent } from './installment-paid/installment-paid.component';
import { StripeRedirectComponent } from './stripe-redirect/stripe-redirect.component';
import { GopayRedirectComponent } from './gopay-redirect/gopay-redirect.component';

export const PAYMENT_GATE_REDIRECT_IFRAME_STATUS_ID = 'LEKTORY_PAYMENT_GATE_REDIRECT_IFRAME_STATUS_ID';
export const PAYMENT_GATE_REDIRECT_IFRAME_ACTION_ID = 'LEKTORY_PAYMENTGATE_REDIRECT_IFRAME_ACTION';

export enum PaymentGatePaymentStatus {
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  UNKNOWN = 'UNKNOWN'
};
export enum PaymentGatePaymentAction {
  PAY = 'PAY',
  FINISH = 'FINISH',
};

const routes: Route[] = [
  {
    path: 'installment-paid/:orderUuid/:installmentNumber',
    component: InstallmentPaidComponent
  },
  {
    path: 'comgate/:paymentStatus',
    component: ComgateRedirectComponent
  },
  {
    path: 'stripe/:paymentStatus',
    component: StripeRedirectComponent
  },
  {
    path: 'gopay/:paymentStatus',
    component: GopayRedirectComponent
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ]
})
export class PaymentGateRedirectModule { }
