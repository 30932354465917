import { Component, HostListener, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-hidden-input-wrap',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule
  ],
  templateUrl: './hidden-input-wrap.component.html',
  styleUrls: ['./hidden-input-wrap.component.scss']
})
export class HiddenInputWrapComponent implements OnInit {

  @Input() label: string = '';
  @Input() editable: boolean = false;
  @Input() FC: FormControl<any> | null = null;

  @HostListener('click', ['$event'])
  onClick(e: Event) {
    // try to find input in host children
    const input = (e.target as HTMLElement).querySelector('input');
    if (input) {
      input.focus();
    }
  }

  ngOnInit(): void {
    if (!this.FC) {
      throw new Error('FC is required');
    }

  }

  onDelete() {
    if (this.FC?.disabled) return;
    
    this.FC?.setValue(undefined);
    this.FC?.markAsDirty();
  }
}
