

<app-modal-header
  [highlighted]="true"
  [bottomPadding]="true"
  [actionButtons]="actionButtons"
  (close)="close()">

  <ng-container *ngIf="state.fetchingReservationDetail$ | async">
    <p-skeleton width="12rem" height="2rem"></p-skeleton>
    <p-skeleton width="7.5rem" height="1rem" class="mt-3 block"></p-skeleton>
  </ng-container>

  <ng-container slot="breadcrumb">
    {{ 'admin.reservations.reservation-detail.modal.breadcrumb' | translate }}
  </ng-container>

  <ng-container *ngIf="(state.reservationDetail$ | async) as d" slot="title">
    {{ d.reservation.beneficiaryUser.name }} {{ d.reservation.beneficiaryUser.surname }}
  </ng-container>

  <ng-container slot="actions">

  </ng-container>

  <ng-container *ngIf="(state.reservationDetail$ | async) as d">
    
    <div class="details-block-wrap text-base text-color">
      <div *ngIf="d.reservation.beneficiaryUser.email as email" class="detail-block">
        {{ email }}
      </div>

      <div *ngIf="d.reservation.beneficiaryUser.parentId" class="detail-block">
        {{ 'admin.reservations.reservation-detail.modal.kid.label' | translate }}
      </div>

      <div *ngIf="getBirthDateCustomData(d.customData) as bd" class="detail-block">
        {{ $any(bd?.value) | age | async }}
      </div>

    </div>

    <p *ngIf="d.reservation.reserverUser as ru" class="m-0 text-color">
      {{ 'admin.reservations.reservation-detail.modal.reserver.label' | translate }}: {{ ru.name }} {{ ru.surname }} <ng-container *ngIf="ru.email">({{ ru.email }})</ng-container>
    </p>

    <p *ngIf="d.partnerReservation?.beneficiaryUser as pbu" class="m-0 text-color">
      {{ 'admin.reservations.reservation-detail.modal.partner.label' | translate }}: {{ pbu.name }} {{ pbu.surname }} <ng-container *ngIf="pbu.email">({{ pbu.email }})</ng-container>
    </p>

  </ng-container>

</app-modal-header>

<div class="modal-body">

  <ng-container *ngIf="state.fetchingReservationDetail$ | async; else fetchedEl">
    <ng-container *ngTemplateOutlet="spinnerEl"></ng-container>
  </ng-container>

  <ng-template #fetchedEl>

    <ng-container *ngIf="(state.reservationDetail$ | async) as d">

      <p *ngIf="d.reservation.shopItem.sessionGroup as sg" class="m-0 text-bluegray-400">
        {{ 'SessionGroupRegistrationType.'+sg.registrationType | translate }}
      </p>

      <div class="flex column-gap-2 align-items-center justify-content-between">
        <div>
          <h5 class="text-dark-blue m-0 mt-1">
            {{ d.reservation.shopItem.title }}
          </h5>
    
          <div *ngIf="d.reservation.shopItem as si" class="details-block-wrap ">
            <span *ngIf="siHelper.getSessionGroupDate(si.sessionGroup) as sgDate" class="detail-block">
              {{ sgDate | translate }}
            </span>
        
            <span *ngIf="siHelper.getSessionGroupTime(si.sessionGroup) as sgTime" class="detail-block">
              {{ sgTime }}
            </span>
        
            <span *ngIf="siHelper.getSessionGroupLocation(si.sessionGroup) as sgLocation" class="detail-block">
              <span [ngbTooltip]="sgLocation" class="text-overflow-ellipsis white-space-nowrap overflow-hidden" style="max-width: 17ch;">
                {{ sgLocation }}
              </span>
            </span>
          </div>
    
          <p class="m-0 text-bluegray-400">
            {{ d.reservation.event.title }}
          </p>
        </div>

        <div class="flex flex-column align-items-center" [class.loading-box]="state.updatingOrderItemPrice$ | async">
          <span class="text-dark-blue">
            <ng-container *ngIf="d.reservation.orderItem as oi; else notInOrder">
              {{ [{ value: oi.basePriceValue, currency: oi.currency }] | price | async | priceCount:oi.count | async  }}
            </ng-container>
            <ng-template #notInOrder>
              {{ d.reservation.shopItem.price | price | async | priceCount:d.reservation.count | async  }}
            </ng-template>
          </span>
          <!-- <span *ngIf="d.reservation.orderId" (click)="onChangeOrderItemPrice(d)" class="text-sm text-bluegray-400 cursor-pointer hover:underline">
            upravit
          </span> -->
        </div>
      </div>


      <div class="scrollable">
        
        <!-- timeline -->
        <div class="timeline-wrap">

          <div class="flex column-gap-2 align-items-center justify-content-between">
            <ng-container *ngTemplateOutlet="reservationCurrentStateDeciderEl"></ng-container>

            <span (click)="timelineCollapsed = !timelineCollapsed" class="cursor-pointer">
              <i *ngIf="timelineCollapsed" class="pi pi-chevron-down"></i>
              <i *ngIf="!timelineCollapsed" class="pi pi-chevron-up"></i>
            </span>
          </div>
  
          <div [@collapse]="timelineCollapsed" class="overflow-hidden">
            <p-timeline [value]="timelineItems">
              <ng-template pTemplate="content" let-item>
      
                <ng-container *ngIf="item.type === TimelineItemType.CREATED_AT">
                  <p class="timeline-item-label">
                    {{ 'TimelineItemType.'+TimelineItemType.CREATED_AT | translate }}
                  </p>
                  <p class="text-bluegray-400 m-0">
                    {{ item.data.reservation.createdAt | localizedDate:'short' }}
                  </p>
                </ng-container>
      
                <ng-container *ngIf="item.type === TimelineItemType.APPROVED_AT">
                  <p class="timeline-item-label">
                    {{ 'TimelineItemType.'+TimelineItemType.APPROVED_AT | translate }}
                  </p>
                  <p class="text-bluegray-400 m-0">
                    {{ item.data.reservation.approvedAt | localizedDate:'short' }}
                  </p>
                </ng-container>
    
                <ng-container *ngIf="item.type === TimelineItemType.CONFIRMED_AT">
                  <p class="timeline-item-label">
                    {{ 'TimelineItemType.'+TimelineItemType.CONFIRMED_AT | translate }}
                  </p>
                  <p class="text-bluegray-400 m-0">
                    {{ item.data.reservation.confirmedAt | localizedDate:'short' }}
                  </p>
                </ng-container>
    
                <ng-container *ngIf="item.type === TimelineItemType.CANCELLED_AT">
                  <p class="timeline-item-label">
                    {{ 'TimelineItemType.'+TimelineItemType.CANCELLED_AT | translate }}
                  </p>
                  <p class="text-bluegray-400 m-0">
                    {{ item.data.reservation.cancelledAt | localizedDate:'short' }}
                  </p>
                </ng-container>
      
                <ng-container *ngIf="item.type === TimelineItemType.ORDERED_AT">
                  <p class="timeline-item-label">
                    {{ 'TimelineItemType.'+TimelineItemType.ORDERED_AT | translate }}
                  </p>
                  <p class="text-bluegray-400 m-0">
                    {{ item.data.reservation.order.createdAt | localizedDate:'short' }}
                  </p>
                </ng-container>
      
              </ng-template>
            </p-timeline>
          </div>
        </div>
        <!-- / timeline -->

        <div class="section-divider"></div>
        
        <!-- custom data -->
        <div *ngIf="d.customData.length" class="">

          <span class="font-bold text-bluegray-400">
            {{ 'admin.reservations.reservation-detail.modal.reservation-data.label' | translate }}
          </span>

          <app-custom-data-inputs [customDataFG]="customDataFG" [userData]="d.customData"></app-custom-data-inputs>

          
        </div>
        <!-- /custom data -->

      </div>


    </ng-container>

  </ng-template>

</div>

<div class="modal-footer">
<!-- 
  <div *ngIf="(state.reservationDetail$ | async) as d" class="flex flex-column flex-1">

    <div class="section-divider mt-0"></div> 
  
    <span class="text-bluegray-400 mb-2">
      Akce
    </span>
  
    <div class="flex justify-content-between">

      <p-button styleClass="p-button-outlined p-button-secondary">
        <div class="flex flex-column">
          <p class="m-0">
            <i class="pi pi-calendar"></i>
          </p>
  
          <p class="m-0 text-sm">
            Změnit expiraci
          </p>
        </div>
      </p-button>

      <p-button styleClass="p-button-outlined p-button-secondary">
        <div class="flex flex-column">
          <p class="m-0">
            <i class="pi pi-shopping-cart"></i>
          </p>
  
          <p class="m-0 text-sm">
            Upravit cenu
          </p>
        </div>
      </p-button>

      <p-button [disabled]="true" styleClass="p-button-outlined p-button-secondary">
        <div class="flex flex-column">
          <p class="m-0">
            <i class="pi pi-arrows-h"></i>
          </p>
  
          <p class="m-0 text-sm">
            Změnit položku
          </p>
        </div>
      </p-button>

      <p-button (click)="onCancel(d)" styleClass="p-button-outlined p-button-danger">
        <div class="flex flex-column">
          <p class="m-0">
            <i class="pi pi-times"></i>
          </p>
  
          <p class="m-0 text-sm">
            <ng-container *ngIf="d.reservation.orderItemId">
              Zrušit položku
            </ng-container>
            <ng-container *ngIf="!d.reservation.orderItemId">
              Zrušit rezervaci
            </ng-container>
          </p>
        </div>
      </p-button>
    </div>

  </div> -->

</div>



<ng-template #spinnerEl>
  <div class="flex justify-content-center align-items-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>


<ng-template #reservationCurrentStateDeciderEl>
  <ng-container *ngIf="reservationDetail?.reservation as r">
  
    <ng-container [ngSwitch]="reservationCurrentState">
      <!-- poptavka -->
      <ng-container *ngSwitchCase="ReservationCurrentState.OFFER_CANCELLED">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.OFFER_CANCELLED' | translate,
          infoText: (r.offer?.cancelledAt | localizedDate:'short'),
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.OFFER_CANCELLED)
        }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ReservationCurrentState.OFFER_CONFIRMED">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.OFFER_CONFIRMED' | translate,
          infoText: (r.order?.completedAt | localizedDate:'short'),
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.OFFER_CONFIRMED)
        }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ReservationCurrentState.OFFER_IN_PREPARATION">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.OFFER_IN_PREPARATION' | translate,
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.OFFER_IN_PREPARATION)
        }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ReservationCurrentState.OFFER_ISSUED">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.OFFER_ISSUED' | translate,
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.OFFER_ISSUED)
        }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ReservationCurrentState.OFFER_ISSUED__ORDER_PENDING">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.OFFER_ISSUED__ORDER_PENDING' | translate,
          infoText: 'admin.reservations.reservation-detail.modal.info-text.maturity.label' | translate:{ date: (r.order?.maturity | localizedDate:'short') },
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.OFFER_ISSUED__ORDER_PENDING)
        }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ReservationCurrentState.OFFER_NEW">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.OFFER_NEW' | translate,
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.OFFER_NEW)
        }"></ng-container>
      </ng-container>
      <!-- /poptavka -->
  
  
      <!-- reservation with order -->
      <ng-container *ngSwitchCase="ReservationCurrentState.ORDER_AWAITING_ON_SITE_PAYMENT">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.ORDER_AWAITING_ON_SITE_PAYMENT' | translate,
          infoText: 'admin.reservations.reservation-detail.modal.info-text.on-site-payment.label' | translate,
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.ORDER_AWAITING_ON_SITE_PAYMENT)
        }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ReservationCurrentState.ORDER_CANCELLED">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.ORDER_CANCELLED' | translate,
          infoText: (r.cancelledAt | localizedDate:'short'),
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.ORDER_CANCELLED)
        }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ReservationCurrentState.ORDER_COMPLETED">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.ORDER_COMPLETED' | translate,
          infoText: (r.order?.completedAt | localizedDate:'short'),
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.ORDER_COMPLETED)
        }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ReservationCurrentState.ORDER_PARTIALLY_COMPLETED">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.ORDER_PARTIALLY_COMPLETED' | translate,
          infoText: (r.order?.completedAt | localizedDate:'short'),
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.ORDER_PARTIALLY_COMPLETED)
        }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ReservationCurrentState.ORDER_PENDING">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.ORDER_PENDING' | translate,
          infoText: 'admin.reservations.reservation-detail.modal.info-text.maturity.label' | translate:{ date: (r.order?.maturity | localizedDate:'short') },
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.ORDER_PENDING)
        }"></ng-container>
      </ng-container>
      <!-- /reservation with order -->
  
  
      <!-- reservation without order -->
      <ng-container *ngSwitchCase="ReservationCurrentState.RESERVATION_APPROVED">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.RESERVATION_APPROVED' | translate,
          infoText: 'admin.reservations.reservation-detail.modal.info-text.reservation-expiration.label' | translate:{ date: (r.expiration | localizedDate:'short') },
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.RESERVATION_APPROVED)
        }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ReservationCurrentState.RESERVATION_CANCELLED">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.RESERVATION_CANCELLED' | translate,
          infoText: (r.cancelledAt | localizedDate:'short'),
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.RESERVATION_CANCELLED)
        }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ReservationCurrentState.RESERVATION_CONFIRMED">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.RESERVATION_CONFIRMED' | translate,
          infoText: (r.confirmedAt | localizedDate:'short'),
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.RESERVATION_CONFIRMED)
        }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ReservationCurrentState.RESERVATION_SUBMITTED">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.RESERVATION_SUBMITTED' | translate,
          infoText: (r.createdAt | localizedDate:'short'),
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.RESERVATION_SUBMITTED)
        }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ReservationCurrentState.RESERVATION_WAITING">
        <ng-container *ngTemplateOutlet="reservationCurrentStateEl, context: {
          stateLabel: 'ReservationCurrentState.RESERVATION_WAITING' | translate,
          color: ReservationCurrentStateColorMap.get(ReservationCurrentState.RESERVATION_WAITING)
        }"></ng-container>
      </ng-container>
      <!-- /reservation without order -->
  
      <ng-container *ngSwitchDefault>
        not_implemented
        {{ utilsService.logError('Missing switch case '+reservationCurrentState+' in ReservationDetailModalComponent') }}
      </ng-container>
    </ng-container>
  
  </ng-container>
</ng-template>

<ng-template #reservationCurrentStateEl let-stateLabel="stateLabel" let-infoText="infoText" let-color="color">

  <div class="flex column-gap-2 align-items-center relative z-1">

    <p-tag [value]="stateLabel" [style]="{'background': 'var('+color+')'}"></p-tag>

    <span *ngIf="infoText" class="text-sm" [style]="{'color': 'var('+color+')'}">
      {{ infoText | translate }}
    </span>

  </div>

</ng-template>