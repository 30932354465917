import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IReservation } from 'src/app/shared/models/reservation/reservation.model';
import { WebcomponentType, WebcomponentsService } from 'src/app/shared/services/webcomponents.service';
import { ReservationState } from 'src/app/shared/enums/reservation/reservation-states.enum';
import { PricePipe } from 'src/app/shared/pipes/price.pipe';
import { StringifyPipe } from 'src/app/shared/pipes/stringify.pipe';
import { PriceCountPipe } from 'src/app/shared/pipes/price-count.pipe';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IPrice } from 'src/app/shared/models/price/price.model';
import { Currency } from 'src/app/shared/enums/price/currencies.enum';
import { DiscountType } from 'src/app/shared/enums/discount/discount-types.enum';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyChangerComponent, CurrencyChangerType } from 'src/app/shared/components/currency-changer/currency-changer.component';

@Component({
  selector: 'td[app-price-cell]',
  standalone: true,
  imports: [
    CommonModule, PriceCountPipe,
    PricePipe, StringifyPipe, TranslateModule, NgbTooltipModule, CurrencyChangerComponent
  ],
  templateUrl: './price-cell.component.html',
  styleUrls: ['./price-cell.component.scss'],
})
export class PriceCellComponent {
  ReservationState = ReservationState;
  CurrencyChangerType = CurrencyChangerType;

  _reservation: IReservation | undefined;
  get reservation() { return this._reservation; }
  @Input() set reservation(val: IReservation | undefined) { this._reservation = val;  this.calcDiscountedPrice(); this.calcTooltip(); }

  discountedPrice: IPrice[] = [];
  priceTooltip: string | null = null;
  discountedPriceWithoutVat: IPrice[] = [];
  discountsStrings: string[] = [];
  additionalDiscountsString: string = '';
  vat: number = 0;

  constructor(
    private translate: TranslateService
  ) {
  }

  private calcDiscountedPrice() {
    if (this.reservation?.orderItem) {
      // Calculate appliedDiscounts
      const orderItemCurrency = this.reservation.orderItem.currency;
      const discountValue = this.reservation.appliedDiscounts.map((appliedDiscount) => appliedDiscount.discountValue.find((p) => p.currency === orderItemCurrency)?.value || 0).sum();
      const discountedValue = ((this.reservation.orderItem?.basePriceValue || 0) * (this.reservation.orderItem?.count || 0)) - discountValue;
      const discountedValueWithoutVat = discountedValue / (1 + +(this.reservation.orderItem?.vat || 0));

      this.discountedPrice.push({
        currency: this.reservation.orderItem?.currency || Currency.CZK,
        value: (discountedValue < 0) ? 0 : discountedValue
      });

      // If price without VAT is 0, set vat to 0 so the price won't be showed
      if (!discountedValueWithoutVat) this.vat = 0;

      this.discountedPriceWithoutVat.push({
        currency: this.reservation.orderItem?.currency || Currency.CZK,
        value: (discountedValueWithoutVat < 0) ? 0 : discountedValueWithoutVat
      });
    } else {
      const discounts = this.getFilteredApplicableDiscounts();
      this.reservation?.shopItem?.price.map((price) => {
        let discountedShopItemPrice = price.value || 0;
        discounts.forEach((discount) => discountedShopItemPrice *= (1 - discount.percentValue) );
        const discountedShopItemPriceWithoutVat = discountedShopItemPrice / (1 + +(this.reservation?.shopItem?.vat || 0));

        // If price without VAT is 0, set vat to 0 so the price won't be showed
        if (!discountedShopItemPriceWithoutVat) this.vat = 0;

        this.discountedPrice.push({
          currency: price.currency,
          value: (discountedShopItemPrice < 0) ? 0 : discountedShopItemPrice
        });
        this.discountedPriceWithoutVat.push({
          currency: price.currency,
          value: (discountedShopItemPriceWithoutVat < 0) ? 0 : discountedShopItemPriceWithoutVat
        });
      });
    }
  }

  private calcTooltip() {
    let discounts: { percentValue: number; title: string; }[];
    let count: number;
    if (this.reservation?.orderId) {
      discounts = this.reservation.appliedDiscounts;
      this.vat = this.reservation.orderItem?.vat || 0;
      count = this.reservation.orderItem?.count || 1;
    } else {
      discounts = this.getFilteredApplicableDiscounts();
      this.vat = +(this.reservation?.shopItem?.vat || 0);
      count = this.reservation?.count || 1;
    }
    discounts.forEach((d) => d.percentValue /= count);
    // If the reservation has count > 1 and the discount is applied multiple times, calculate the percent values accordingly
    const discountsPercentages: { [discountHash: string]: { percentValue: number; title: string; } } = {};
    discounts.forEach((discount) => {
      const hash = `${discount.title}${discount.percentValue}`;
      if (hash in discountsPercentages) {
        discountsPercentages[hash].percentValue += discount.percentValue;
      } else {
        discountsPercentages[hash] = {
          title: discount.title,
          percentValue: discount.percentValue
        };
      }
    });
    this.discountsStrings =  Object.values(discountsPercentages).map((appliedDiscount) => {
      return `${(appliedDiscount.percentValue * 100)}% - ${appliedDiscount.title}`;
    });

    if (!this.reservation?.orderId && this.reservation?.state !== ReservationState.CANCELLED) {
      if (this.discountsStrings.length) {
        this.additionalDiscountsString = 'admin.reservations.table.cell.price-noorder-more.tooltip'
      } else {
        this.additionalDiscountsString = 'admin.reservations.table.cell.price-noorder.tooltip'
      }
    }
  }

  private getFilteredApplicableDiscounts() {
    return this.reservation?.applicableDiscounts.filter((discount) => {
      if (discount.type === DiscountType.PROMO_CODE) {
        return true;
      }
      if (discount.type === DiscountType.SELECTABLE_BY_CUSTOMER) {
        return this.reservation?.selectedUserDiscountIds.includes(discount.id);
      }
      return false;
    }) || [];
  }

}
