


<ng-container *ngIf="reservation as r">
  
  <div class="row">
    <span class="font-bold" [ngbTooltip]="discountedPriceTooltip">
      {{ discountedPrice | price | async }}
    </span>
  </div>

  <div class="row">
    <span class="text-xs" [ngbTooltip]="'admin.reservations.table.cell.price-base.tooltip' | translate">
      <ng-container *ngIf="r.order && r.orderItem; else notInOrder">
        {{ (([{ value: r.orderItem.basePriceValue, currency: r.orderItem.currency }]) | price | async) | priceCount:r.count | async }}
      </ng-container>
      <ng-template #notInOrder>
        {{ ((r.shopItem?.price ?? []) | price | async) | priceCount:r.count | async }}
        <app-currency-changer
          [price]="r.shopItem?.price ?? []"
          [type]="CurrencyChangerType.ICON"
          [appendTo]="'body'">
        </app-currency-changer>
      </ng-template>
    </span>
  </div>

</ng-container>

<ng-template #discountedPriceTooltip>
  <div class="text-left">
    <div>
      {{
        vat ? ('admin.reservations.table.cell.price-vat.tooltip' | translate) : ('admin.reservations.table.cell.price-no-vat.tooltip' | translate)
      }}
      {{
        discountsStrings.length ? ('admin.reservations.table.cell.price-after-discounts-label.tooltip' | translate) : ''
      }}
    </div>
    <div *ngIf="vat">
      {{ ('admin.reservations.table.cell.price-without-vat.tooltip' | translate:{price: discountedPriceWithoutVat | price | async  }) }}
    </div>
    <div>
      <ng-container *ngFor="let d of discountsStrings">
        <div>
          • {{ d }}
        </div>
      </ng-container>
    </div>
    <div *ngIf="additionalDiscountsString">
      {{ additionalDiscountsString | translate }}
    </div>
  </div>
</ng-template>

<!-- {{
    discountsStrings
      ? vat
        ? ('admin.reservations.table.cell.price-vat.tooltip' | translate:{ discounts: discountsString, price: discountedPriceWithoutVat | price | async }) + (additionalDiscountsString | translate)
        : ('admin.reservations.table.cell.price-no-vat.tooltip' | translate:{ discounts: discountsString + (additionalDiscountsString | translate) }) + (additionalDiscountsString | translate)
      : additionalDiscountsString ? (additionalDiscountsString | translate) : null
}} -->