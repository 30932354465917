

<ng-container *ngIf="order as o">

  <div (click)="onItem()" class="order-wrap flex surface-card p-3 cursor-pointer" [class.opened]="!collapsed">
  
    <div class="flex flex-column flex-1 row-gap-1">
      <h5 class="m-0 font-normal">
        {{'shared.order-list-item.order-number' | translate:{ orderNumber: ''+o.orderId+o.randomNumber } }}
      </h5>
  
      <ng-container [ngSwitch]="order.state">
        <ng-container *ngSwitchCase="OrderState.COMPLETED">
          <span class="flex align-items-center text-green-500">
            <i class="pi pi-check mr-2"></i>
            <span>
              {{'shared.order-list-item.COMPLETED.label' | translate:{date: o.completedAt | localizedDate:'shortDate'} }}
            </span>
          </span>
        </ng-container>

        <ng-container *ngSwitchCase="OrderState.PARTIALLY_COMPLETED">
          <span class="flex align-items-center text-cyan-400">
            <i class="pi pi-check mr-2"></i>
            <span>
              {{'shared.order-list-item.PARTIALLY_COMPLETED.label' | translate}}
            </span>
          </span>
        </ng-container>

        <ng-container *ngSwitchCase="OrderState.PENDING">
          <span *ngIf="!orderHelper.isOverdue(order.maturity)" class="flex align-items-center text-orange-400">
            <i class="pi pi-clock mr-2"></i>
            <span *ngIf="!orderHelper.isPartiallyPaid(order)">
              {{'shared.order-list-item.PENDING.label' | translate: {date: o.maturity | localizedDate:'d.M.'} }}
            </span>
            <span *ngIf="orderHelper.isPartiallyPaid(order)">
              {{'shared.order-list-item.PENDING.partially-paid-label' | translate }}
            </span>
          </span>
          <span *ngIf="!orderHelper.isExpired(order.expiration) && orderHelper.isOverdue(order.maturity)" class="flex align-items-center text-red-500">
            <i class="pi pi-exclamation-triangle mr-2"></i>
            <span>
              {{'shared.order-list-item.OVERDUE.label' | translate: {date: o.expiration | localizedDate:'d.M.'} }}
            </span>
          </span>
          <span *ngIf="orderHelper.isExpired(order.expiration)" class="flex align-items-center text-red-500">
            <i class="pi pi-exclamation-triangle mr-2"></i>
            <span>
              {{'shared.order-list-item.EXPIRED.label' | translate: {date: o.expiration | localizedDate:'d.M.'} }}
            </span>
          </span>
        </ng-container>

        <ng-container *ngSwitchCase="OrderState.AWAITING_ON_SITE_PAYMENT">
          <span class="flex align-items-center text-blue-500">
            <i class="pi pi-check mr-2"></i>
            <span>
              {{'shared.order-list-item.AWAITING_ON_SITE_PAYMENT.label' | translate}}
            </span>
          </span>
        </ng-container>

        <ng-container *ngSwitchCase="OrderState.CANCELLED">
          <span class="flex align-items-center text-color-secondary">
            <i class="pi pi-times mr-2"></i>
            <span>
              {{'shared.order-list-item.CANCELLED.label' | translate}}
            </span>
          </span>
        </ng-container>

        <ng-container *ngSwitchDefault>
          {{ missingStateTemplate(order.state) }}
        </ng-container>
      </ng-container>
    </div>
  
    <div class="flex align-items-center">
  
      <span class="flex align-items-center justify-content-center surface-ground border-circle p-2 cursor-pointer">
        <i *ngIf="!collapsed" class="pi pi-chevron-up"></i>
        <i *ngIf="collapsed" class="pi pi-chevron-down"></i>
      </span>
  
    </div>
  
  </div>
  <div [@collapse]="collapsed" class="collapse-wrap surface-card border-round-bottom" [class.opened]="!collapsed">
  
    <div class="pt-2">
  
      <ng-container *ngIf="order?.orderItems ?? [] as orderItems">
  
        <ng-container *ngFor="let item of orderItems; let i = index">
          <div class="order-item-wrap {{ i < orderItems.length-1 ? 'border-bottom-1' : ''}} border-none border-dashed">

            <div class="item-wrap p-3 ">
    
              <div class="flex ">
                <div class="flex-1">
                  <h5 class="m-0 mb-1">
                    {{ item.itemTitle | itemTitle:item.count | async }}
                  </h5>
    
                  <div class=" text-color-secondary">
  
                    <span *ngIf="orderHelper.getIsOrderItemCancelled(item)" class="flex align-items-center">
                      <i class="pi pi-times mr-2"></i>
                      <span>
                        {{'shared.order-list-item.order-item.CANCELLED' | translate}}
                      </span>
                    </span>
  
                    <div *ngIf="item.shopItem as si" class="details-block-wrap ">
                      <span *ngIf="siHelper.getSessionGroupDate(si.sessionGroup) as sgDate" class="detail-block">
                        {{ sgDate | translate }}
                      </span>
                  
                      <span *ngIf="siHelper.getSessionGroupTime(si.sessionGroup) as sgTime" class="detail-block">
                        {{ sgTime }}
                      </span>
                  
                      <span *ngIf="siHelper.getSessionGroupLocation(si.sessionGroup) as sgLocation" class="detail-block">
                        <span [ngbTooltip]="sgLocation" class="text-overflow-ellipsis white-space-nowrap overflow-hidden" style="max-width: 17ch;">
                          {{ sgLocation }}
                        </span>
                      </span>
                    </div>
    
                    <div *ngIf="item.reservation.partnerReservation?.beneficiaryUser as bu" class="flex align-items-center">
                      <!-- <i class="pi pi-ticket mr-2"></i> -->
                      <span>
                        {{'shared.order-list-item.in-pair-with' | translate: { partner: bu | userNameOrEmail } }}
                      </span>
                    </div>
    
                    <ng-container *ngIf="!orderHelper.getIsOrderItemCancelled(item)">
  
                      <div *ngIf="item.reservation.partnerReservation?.order?.state === OrderState.PENDING && item.reservation.partnerReservation?.reserverUserId !== item.reservation.reserverUserId" class="flex align-items-center text-orange-400">
                        <i class="pi pi-clock mr-2"></i>
                        <span>
                          {{'shared.order-list-item.waiting-for-partner-payment' | translate:{ date: item.reservation.partnerReservation?.order?.expiration | localizedDate:'d.M.' } }}</span>
                      </div>
      
                      <div *ngIf="!item.reservation.partnerReservation?.order && item.reservation.partnerReservation?.state === ReservationState.APPROVED && item.reservation.partnerReservation?.reserverUserId !== item.reservation.reserverUserId" class="flex align-items-center text-orange-400">
                        <i class="pi pi-clock mr-2"></i>
                        <span>
                          {{'shared.order-list-item.waiting-for-partner-order' | translate:{ date: item.reservation.partnerReservation?.expiration | localizedDate:'d.M.' } }}
                        </span>
                      </div>
  
                    </ng-container>
    
                  </div>
    
                </div>
    
                <span class="flex align-items-center" [class.line-through]="orderHelper.getIsOrderItemCancelled(item)">
                  {{ [{ value: item.basePriceValue, currency: item.currency }] | price | async | priceCount:item.count | async  }}
                </span>
              </div>
    
            </div>
    
            <!-- DISCOUNTS -->
            <ng-container *ngFor="let discount of item.discounts; let k = index">
    
              <div class="discount-wrap p-3 border-top-1 border-none border-dashed">
    
                <div class="flex">
                  <div class="flex-1">
                    <h5 class="m-0 mb-1">
                      {{ discount.title }}
                    </h5>
      
                  </div>
      
                  <span class="flex align-items-center">
                    {{ discount.discountValue | price:item.currency:true | async }}
                  </span>
          
                </div>
    
                <div class="info">
                  <div class="details-block-wrap ">
                    <span class="detail-block">
                      {{ discount.percentValue | percent }}
                    </span>
                    
                    <span *ngIf="discount.previousPurchase" class="detail-block">
                      {{ 'discount.previous-purchase' | translate }}
                    </span>
                  </div>
                </div>
  
  
              </div>
    
            </ng-container>
            <!-- / -->

          </div>
  
        </ng-container>
      </ng-container>
  
    </div>
  
  </div>

</ng-container>
