

<ng-container *ngIf="reservation as r">

  <ng-container *ngIf="r.shopItem?.sessionGroup?.registrationType as t">

    <ng-container [ngSwitch]="t">
      <ng-container *ngSwitchCase="SessionGroupRegistrationType.INDIVIDUALS_COUPLE">
        <ng-container *ngIf="r.partner">
          <ng-container *ngTemplateOutlet="withPartner, context: {
            partner: r.partner,
            partnerReservation: r.partnerReservation
          }"></ng-container>
        </ng-container>
        <ng-container *ngIf="!r.partner">
          <ng-container *ngTemplateOutlet="typeLabel, context: {
            type: 'SessionGroupRegistrationType.'+SessionGroupRegistrationType.INDIVIDUAL | translate
          }"></ng-container>

          <ng-container *ngTemplateOutlet="roleLabel, context: {
            role: r.sessionGroupCoupleRole
          }"></ng-container>
        </ng-container>
      </ng-container>
    
      <ng-container *ngSwitchCase="SessionGroupRegistrationType.COUPLE">
        <ng-container *ngTemplateOutlet="withPartner, context: {
          partner: r.partner,
          partnerReservation: r.partnerReservation
        }"></ng-container>
      </ng-container>
    
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="typeLabel, context: {
          type: 'SessionGroupRegistrationType.'+t | translate
        }"></ng-container>
      </ng-container>
    </ng-container>
    
    <ng-template #withPartner let-partner="partner" let-partnerReservation="partnerReservation">
      <ng-container *ngIf="partner && partnerReservation">
        <div class="row">
          <ng-container *ngTemplateOutlet="typeLabel, context: {
            type: 'SessionGroupRegistrationType.'+SessionGroupRegistrationType.COUPLE | translate
          }"></ng-container>
          <ng-container *ngTemplateOutlet="roleLabel, context: {
            role: r.sessionGroupCoupleRole
          }"></ng-container>
          <span class="text-xs">s</span>
        </div>
        <div [ngbTooltip]="partner.email" class="row">
          <span class="white-space-nowrap">
            {{ partner | userNameOrEmail }} 
            <ng-container *ngTemplateOutlet="roleLabel, context: {
              role: partnerReservation.sessionGroupCoupleRole,
            }"></ng-container>
          </span>
        </div>
      </ng-container>
    </ng-template>
      

  </ng-container>

</ng-container>

<ng-template #typeLabel let-type="type">
  <span class="font-bold">
    {{ type | translate }}
  </span>
</ng-template>


<ng-template #roleLabel let-role="role">

  <span *ngIf="reservation?.shopItem?.sessionGroup as sg" class="text-xs">
    
    <ng-container *ngIf="role === SessionGroupCoupleRole.A">
      ({{ sg.roleLabelA?.[0] | uppercase }})
    </ng-container>
  
    <ng-container *ngIf="role === SessionGroupCoupleRole.B">
      ({{ sg.roleLabelB?.[0] | uppercase }})
    </ng-container>

  </span>

</ng-template>