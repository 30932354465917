import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ModalHeaderComponent } from '../components/modal-header/modal-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { Modal } from '../modal';
import { BasicModalDataType, BasicModalMode } from '../../services/modal.service';

export enum BasicModalResult {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}

@Component({
  selector: 'app-basic-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    ModalHeaderComponent, TranslateModule
  ],
  templateUrl: './basic-modal.component.html',
  styleUrl: './basic-modal.component.scss'
})
export class BasicModalComponent extends Modal implements OnInit, OnDestroy {
  BasicModalResult = BasicModalResult;
  BasicModalMode = BasicModalMode;

  @Input() title: string = '';
  @Input() text: string = '';
  @Input({ required: true }) data!: BasicModalDataType;
  @Input() template: TemplateRef<any> | undefined;

  @Output() result = new EventEmitter<BasicModalResult | null>();

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  onResult(btn: BasicModalResult, action: () => void) {
    action();
    this.result.emit(btn);
    this.close();
  }


  ngOnDestroy(): void {
    this.result.emit(null);
  }
}
