import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationState } from 'src/app/shared/enums/reservation/reservation-states.enum';
import { TagModule } from 'primeng/tag';
import { OrderState } from 'src/app/shared/enums/order/order-states.enum';
import { IReservation } from 'src/app/shared/models/reservation/reservation.model';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { OfferState } from 'src/app/shared/enums/offer/offer-states.enum';
import { ShopItemReservationMode } from 'src/app/shared/enums/shop-item/shop-item-reservation-mode.enum';
import { ReservationCurrentState, ReservationCurrentStateColorMap, ReservationHelperService } from 'src/app/shared/services/helpers/reservation-helper.service';
import { TranslateModule } from '@ngx-translate/core';
import { OrderHelperService } from 'src/app/shared/services/helpers/order-helper.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'td[app-tag-cell]',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    NgbTooltipModule, TranslateModule
  ],
  templateUrl: './tag-cell.component.html',
  styleUrls: ['./tag-cell.component.scss']
})
export class TagCellComponent {
  ReservationState = ReservationState;
  OfferState = OfferState;
  OrderState = OrderState;
  ReservationCurrentState = ReservationCurrentState;
  ReservationCurrentStateColorMap = ReservationCurrentStateColorMap;

  
  _reservation: IReservation | undefined;
  get reservation(): IReservation | undefined { return this._reservation; };
  @Input() set reservation(v: IReservation | undefined) {
    this._reservation = v;
    this.free = v?.orderItem?.basePriceValue ? !(+v.orderItem.basePriceValue) : false;
    this.currentState = v ? this.resHelper.getCurrentState(v) : null;
    this.orderExpired = v?.order ? this.orderHelper.isExpired(v.order.expiration) : false;
    this.reservationExpired = v?.expiration ? this.orderHelper.isExpired(v.expiration) : false;
  };
  currentState: ReservationCurrentState | null = null;
  orderExpired: boolean = false;
  reservationExpired: boolean = false;
  free = false;

  constructor(
    private resHelper: ReservationHelperService,
    private orderHelper: OrderHelperService,
    public utilsService: UtilsService,
  ) {}


}
