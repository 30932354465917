

<ng-container  *ngIf="reservation as r">

  <div class="row">
    <span
      (click)="navigateToShopItem(r.event?.uuid, r.shopItem?.uuid)"
      [ngbTooltip]="r.shopItem?.code ? r.shopItem?.code + ' - ' + r.shopItem?.title : r.shopItem?.title"
      role="link"
      style="display: inherit;"
      class="font-bold hover:underline cursor-pointer m-0 ellipsis">
        {{ r.shopItem?.title | itemTitle:r.count | async }}
    </span>
  </div>

  <div class="row">

    <div class="details-block-wrap">
      <span *ngIf="datesString" class="detail-block">
        {{ datesString }}
      </span>
  
      <span *ngIf="locationString as l" class="detail-block">
        <span class="location">
          {{ l }}
        </span>
      </span>
    
      <span
        (click)="navigateToEvent(r.event?.uuid)"
        [ngbTooltip]="r.event?.code ? r.event?.title : r.event?.code ?? null"
        container="body"
        class=" m-0 detail-block">
        <span class="hover:underline cursor-pointer event-ellipsis">
          {{ r.event?.code ? r.event?.code : r.event?.title }}
        </span>
      </span>
    </div>

  </div>


</ng-container>