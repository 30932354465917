import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from 'src/app/shared/modals/modal';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SkeletonModule } from 'primeng/skeleton';
import { IActionButton, ModalHeaderComponent } from 'src/app/shared/modals/components/modal-header/modal-header.component';
import { IReservation } from 'src/app/shared/models/reservation/reservation.model';
import { IUser } from 'src/app/shared/models/user/user.model';
import { IOrder } from 'src/app/shared/models/order/order-model';
import { IOrderItem } from 'src/app/shared/models/order-item/order-item.model';
import { ISession } from 'src/app/shared/models/session-group/session.model';
import { ILocation } from 'src/app/shared/models/location/location.model';
import { DataType } from 'src/app/shared/enums/collecting-data/data-type.enum';
import { IReservationDetailState, ReservationDetailStoreService } from 'src/app/shared/services/store/reservation-detail-store.service';
import { AgePipe } from 'src/app/shared/pipes/age.pipe';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ShopItemHelperService } from 'src/app/shared/services/helpers/shop-item-helper.service';
import { IEvent } from 'src/app/shared/models/event/event.model';
import { PricePipe } from 'src/app/shared/pipes/price.pipe';
import { PriceCountPipe } from 'src/app/shared/pipes/price-count.pipe';
import { TimelineModule } from 'primeng/timeline';
import { finalize, Subscription, take } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { IOffer } from 'src/app/shared/models/offer/offer.model';
import { ReservationCurrentState, ReservationCurrentStateColorMap, ReservationHelperService } from 'src/app/shared/services/helpers/reservation-helper.service';
import { TagModule } from 'primeng/tag';
import { collapseAnimation } from 'src/app/shared/animations/collapse.animation';
import { GetCustomDataResultType } from 'src/app/shared/services/entities/users/customer/customers.service';
import { CustomDataInputsComponent } from 'src/app/pages/admin/components/custom-data-inputs/custom-data-inputs.component';
import { OrderItemPriceModalComponent } from '../order-item-price-modal/order-item-price-modal.component';
import { BasicModalMode, ModalService } from 'src/app/shared/services/modal.service';
import { CancelReservationModalComponent } from 'src/app/shared/modals/cancel-reservation-modal/cancel-reservation-modal.component';
import { ActionBy } from 'src/app/shared/enums/utils/action-by.enum';
import { TranslateModule } from '@ngx-translate/core';
import { ReservationsService } from 'src/app/shared/services/entities/reservations/reservations.service';
import { BasicModalComponent, BasicModalResult } from 'src/app/shared/modals/basic-modal/basic-modal.component';
import { OrgReservationsStoreService } from 'src/app/shared/services/store/org-reservations-store.service';

export interface IReservationDetail {
  reservation: IReservation & {
    reserverUser: IUser;
    beneficiaryUser: IUser;
    order: IOrder | null;
    orderItem: IOrderItem | null;
    shopItem: {
      sessionGroup: {
        sessions: (ISession & { location: ILocation | null })[]
      } | null
    };
    event: IEvent;
    offer: IOffer | null;
  };
  partnerReservation: IReservation & {
    beneficiaryUser: IUser;
    order: IOrder | null;
    orderItem: IOrderItem | null;
  } | null;
  customData: GetCustomDataResultType[];
}

interface ITimelineItem {
  type: TimelineItemType;
  data: IReservationDetail;
}
enum TimelineItemType {
  CREATED_AT = 'CREATED_AT',
  APPROVED_AT = 'APPROVED_AT',
  CANCELLED_AT = 'CANCELLED_AT',
  CONFIRMED_AT = 'CONFIRMED_AT',
  ORDERED_AT = 'ORDERED_AT',
  
  SPACER = 'SPACER',
}

@Component({
  selector: 'app-reservation-detail-modal',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    ButtonModule, ProgressSpinnerModule, SkeletonModule, TagModule, TimelineModule,
    ModalHeaderComponent, AgePipe, PricePipe, PriceCountPipe, LocalizedDatePipe, CustomDataInputsComponent,
    NgbTooltipModule,
  ],
  templateUrl: './reservation-detail-modal.component.html',
  styleUrls: ['./reservation-detail-modal.component.scss'],
  animations: [ collapseAnimation ]
})
export class ReservationDetailModalComponent extends Modal implements OnInit, OnDestroy {
  TimelineItemType = TimelineItemType;
  ReservationCurrentState = ReservationCurrentState;
  ReservationCurrentStateColorMap = ReservationCurrentStateColorMap;

  @Input() reservationId: number | undefined;

  state: IReservationDetailState = this.reservationDetailStore.state;
  reservationDetail: IReservationDetail | null = null;
  @HostBinding('class.loading-box') updatingReservationDetail: boolean = false;
  reservationCurrentState: ReservationCurrentState | null = null;
  timelineItems: ITimelineItem[] = [];
  timelineCollapsed: boolean = false;

  customDataFG = this.utilsService.createCustomDataFG([]);
  actionButtons: IActionButton[] = [];

  subs: Subscription[] = [];

  constructor(
    private orgReservationsStore: OrgReservationsStoreService,
    private reservationDetailStore: ReservationDetailStoreService,
    public siHelper: ShopItemHelperService,
    private resHelper: ReservationHelperService,
    public utilsService: UtilsService,
    private modalsService: ModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.reservationDetailStore.init(this.reservationId!);

    this.subs.push(
      this.state.reservationDetail$.subscribe((reservationDetail) => {
        this.reservationDetail = reservationDetail;

        this.customDataFG = this.utilsService.createCustomDataFG(reservationDetail?.customData ?? []);
        this.customDataFG.disable();

        this.createTimelineItems(reservationDetail);

        this.reservationCurrentState = reservationDetail ? this.resHelper.getCurrentState(reservationDetail.reservation) : null;
      }),

      this.state.updatingReservationDetail$.subscribe((updating) => {
        this.updatingReservationDetail = updating;
      }),
    );

    this.createActionButtons();
  }

  private createActionButtons() {
    if (!this.reservationId) return;

    this.actionButtons = [
      {
        icon: 'pi pi-trash text-red-500',
        onClick: () => this.modalsService.openBasicModal(BasicModalComponent, {
          buttonMode: BasicModalMode.TWO_BUTTON,
          title: 'admin.reservations.reservation-detail.modal.delete-reservation-modal.title',
          text: 'admin.reservations.reservation-detail.modal.delete-reservation-modal.text',
          leftBtnLabel: 'admin.reservations.reservation-detail.modal.delete-reservation-modal.cancel-btn.label',
          leftBtnLabelData: {},
          leftBtnWrapClass: 'flex-1',
          leftBtnClass: 'w-full p-button-text',
          leftBtnAction: () => {},
          rightBtnLabel: 'admin.reservations.reservation-detail.modal.delete-reservation-modal.submit-btn.label',
          rightBtnLabelData: {},
          rightBtnWrapClass: 'flex-1',
          rightBtnClass: 'w-full p-button-danger',
          rightBtnAction: () => {
            this.reservationDetailStore.delete(this.reservationId!).pipe(
              take(1),
            ).subscribe(() => {
              this.orgReservationsStore.reinit();
              this.close();
            });
          },
        })
      }
    ];
  }

  onCancel(d: IReservationDetail) {

    const modalRes = this.modalsService.openCancelReservationModal(CancelReservationModalComponent, d.reservation, d.reservation.order);
    modalRes.pipe(take(1)).subscribe((result) => {
      if (d.reservation.offerId) {
        // cancel offer
        this.reservationDetailStore.cancelOffer({
          sendNotifications: result.sendNotifications,
          stornoFee: result.stornoFee,
          offerId: d.reservation.offerId
        }).subscribe();
      }
      else if (d.reservation.orderItem) {
        // cancel order item
        this.reservationDetailStore.cancel({
          data: [
            {
              reservationId: d.reservation.id,
              note: null,
              stornoFee: result.stornoFee,
              refundToCredit: result.refundToCredit
            }
          ],
          actionBy: ActionBy.ADMIN,
          sendNotifications: result.sendNotifications,
          partnerReservationId: d.reservation.partnerReservationId || undefined
        }).subscribe();
      } else {
        // cancel reservation
        this.reservationDetailStore.cancel({
          data: [
            {
              reservationId: d.reservation.id,
              note: null,
              refundToCredit: result.refundToCredit
            }
          ],
          sendNotifications: result.sendNotifications,
          actionBy: ActionBy.ADMIN,
          partnerReservationId: d.reservation.partnerReservationId || undefined
        }).subscribe();
      }
    });
  }

  onChangeOrderItemPrice(d: IReservationDetail) {
    if (!d.reservation.orderItem) return;
    this.modalsService.openOrderItemPriceModal(
      OrderItemPriceModalComponent,
      d.reservation.orderItem.id,
      d.reservation.id,
      d.reservation.orderItem.basePriceValue,
      d.reservation.orderItem.currency,
      this.reservationDetailStore.updateOrderItemPrice.bind(this.reservationDetailStore)
    );
  }

  private createTimelineItems(d: IReservationDetail | null) {
    const items: ITimelineItem[] = [];

    if (!d) {
      this.timelineItems = [];
      return;
    }

    if (d.reservation.createdAt) {
      items.push({
        type: TimelineItemType.CREATED_AT,
        data: d,
      });
    }
    if (d.reservation.approvedAt) {
      items.push({
        type: TimelineItemType.APPROVED_AT,
        data: d,
      });
    }
    if (d.reservation.order?.createdAt) {
      items.push({
        type: TimelineItemType.ORDERED_AT,
        data: d,
      });
    }
    if (d.reservation.confirmedAt) {
      items.push({
        type: TimelineItemType.CONFIRMED_AT,
        data: d,
      });
    }
    if (d.reservation.cancelledAt) {
      items.push({
        type: TimelineItemType.CANCELLED_AT,
        data: d,
      });
    }

    items.push({
      type: TimelineItemType.SPACER,
      data: d,
    });

    this.timelineItems = items.reverse();
  }

  getBirthDateCustomData(customData: GetCustomDataResultType[]) {
    return customData.find((cd) => cd.dataType === DataType.BIRTHDATE);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
