import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { SystemService } from 'src/app/shared/services/system.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { PaymentGatePaymentAction, PaymentGatePaymentStatus, PAYMENT_GATE_REDIRECT_IFRAME_ACTION_ID, PAYMENT_GATE_REDIRECT_IFRAME_STATUS_ID } from '../payment-gate-redirect.module';
import { TransfersService } from 'src/app/shared/services/entities/transfers/transfers.service';
import { ActionBy } from 'src/app/shared/enums/utils/action-by.enum';
import { ReserverMagicCodeService } from 'src/app/shared/services/reserver-magic-code.service';
import { OrdersService } from 'src/app/shared/services/entities/orders/orders.service';
import { finalize, take } from 'rxjs';
import { ITransfer } from 'src/app/shared/models/transfers/transfer.model';
import { IOrderFE } from 'src/app/shared/models/order/order-model';
import { PricePipe } from 'src/app/shared/pipes/price.pipe';
import { OrderListItemComponent } from 'src/app/shared/components/order-list-item/order-list-item.component';
import { IUserState, UserStoreService } from 'src/app/shared/services/store/user-store.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslateModule } from '@ngx-translate/core';
import { InvoicesHelperService } from 'src/app/shared/services/helpers/invoices-helper.service';
import { ShowInvoiceOrOrdersBtnComponent } from '../components/show-invoice-or-orders-btn/show-invoice-or-orders-btn.component';
import { FinishBtnComponent } from '../components/finish-btn/finish-btn.component';
import { WebcomponentsService } from 'src/app/shared/services/webcomponents.service';
import { TransferState } from 'src/app/shared/enums/transfer/transfer-states.enum';



@Component({
  selector: 'app-payment-gate-status',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    ButtonModule, ProgressSpinnerModule,
    PricePipe, OrderListItemComponent, ShowInvoiceOrOrdersBtnComponent, FinishBtnComponent,
  ],
  templateUrl: './payment-gate-payment-status.component.html',
  styleUrls: ['./payment-gate-payment-status.component.scss']
})
export class PaymentGatePaymentStatusComponent implements OnInit {
  PaymentGatePaymentStatus = PaymentGatePaymentStatus;

  @Input() PG_CODE: string | undefined;
  @Input() paymentStatus: PaymentGatePaymentStatus | undefined;
  @Input() waitUntilSuccess: boolean = false;

  inIframe: boolean | undefined;

  userState: IUserState | undefined;

  interval?: any;

  transfer: ITransfer | undefined;
  order: IOrderFE | undefined;
  fetchingTransfer: boolean = false;
  fetchingOrder: boolean = false;

  constructor(
    private sysService: SystemService,
    private navService: NavigationService,
    private transfersService: TransfersService,
    private reserverMagicCodeService: ReserverMagicCodeService,
    private ordersService: OrdersService,
    private userStoreService: UserStoreService,
    private webcomponentService: WebcomponentsService
  ) {
    this.webcomponentService.getWebcomponentLanguage();
  }

  ngOnInit() {

    this.userState = this.userStoreService.state;
    this.inIframe = this.sysService.isInIframe();

    if (this.inIframe) {
      this.sendPaymentStatusToParent();
    }

    if (!this.paymentStatus) this.navService.goToBase();

    let numOfTries = 5;

    if (this.paymentStatus === PaymentGatePaymentStatus.PAID) {
      if (this.waitUntilSuccess) {
        this.interval = setInterval(() => {
          if (!this.fetchingTransfer) {
            numOfTries--;
            this.fetchData();
            if (numOfTries <= 0) clearInterval(this.interval);
          }
        }, 2000);
      } else {
        this.fetchData();
      }
    }

    let unknownTries = 10;
    // unknown - ie from gopay
    if (this.paymentStatus === PaymentGatePaymentStatus.UNKNOWN) {
      this.waitUntilSuccess = true; // just to be sure
      this.interval = setInterval(() => {
        if (!this.fetchingTransfer) {
          unknownTries--;
          this.fetchData();
          if (unknownTries <= 0) {
            clearInterval(this.interval);
            this.paymentStatus = PaymentGatePaymentStatus.PENDING;
            this.waitUntilSuccess = false;
          }
        }
      }, 5000);
    }
  }

  fetchData() {
    this.fetchTransfer();
    this.fetchOrder();
  }

  fetchTransfer() {
    this.fetchingTransfer = true;
    this.transfersService.getSingleTransfer({
      actionBy: ActionBy.USER,
      PG_CODE: this.PG_CODE,
    }).pipe(
      take(1),
      finalize(() => this.fetchingTransfer = false)
    ).subscribe({
      next: transfer => {
        this.transfer = transfer;
        if (+this.transfer.amount && this.waitUntilSuccess) {
          this.fetchOrder();
          this.paymentStatus = PaymentGatePaymentStatus.PAID;
          this.waitUntilSuccess = false;
          clearInterval(this.interval as unknown as number);
          this.sendPaymentStatusToParent();
        }
        if (this.waitUntilSuccess && this.transfer.state === TransferState.CANCELLED) {
          this.fetchOrder();
          this.waitUntilSuccess = false;
          this.paymentStatus = PaymentGatePaymentStatus.CANCELLED;
          clearInterval(this.interval);
          this.sendPaymentStatusToParent();
        }
      }
    });
  }

  fetchOrder() {
    this.fetchingOrder = true;
    this.ordersService.getSingleByUser({
      // userTransferPG_CODE: this.PG_CODE, // je to potrebné?
      transferPG_CODE: this.PG_CODE,
    }).pipe(
      take(1),
      finalize(() => this.fetchingOrder = false)
    ).subscribe({
      next: order => {
        this.order = order;
      }
    });
  }

  

  onPay() {
    const msg = `${PAYMENT_GATE_REDIRECT_IFRAME_ACTION_ID}|${PaymentGatePaymentAction.PAY}`;
    window.parent.window.postMessage(msg, '*');
  }


  private sendPaymentStatusToParent() {
    if (this.inIframe) {
      const msg = `${PAYMENT_GATE_REDIRECT_IFRAME_STATUS_ID}|${this.PG_CODE}|${this.paymentStatus}`;
      window.parent.window.postMessage(msg, '*');
    }
  }


}
