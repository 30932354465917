

@if (note) {
  <div class="note">

    <div class="flex align-items-baseline justify-content-between">
      <div class="note-author">
        <div class="flex column-gap-2 align-items-center">
          @if (note.authorStaticData.photoUrls?.[ImageSize._64x64]; as imgUrl) {
            <p-avatar [image]="imgUrl" shape="circle"></p-avatar>
          } @else {
            <p-avatar [label]="note.authorStaticData | userInitials" shape="circle"></p-avatar>
          }
    
          <div class="flex flex-column justify-content-center">
            <p class="m-0 font-bold text-sm line-height-1 text-dark-blue">{{ note.authorStaticData | userNameOrEmail }}</p>
            <p class="m-0 text-bluegray-300 text-xs">{{ note.createdAt | localizedDate: 'short' }}</p>
          </div>
        </div>
    
      </div>
  
      <div class="note-actions">

        <div (click)="onStateToggle()" class="cursor-pointer" [class.loading-box]="updatingState">
          @if (note.state === CustomerNoteState.UNSOLVED) {
            <i [ngbTooltip]="'admin.customer-note.state.UNSOLVED.tooltip' | translate" class="pi pi-check text-primary"></i>
          }
          @if (note.state === CustomerNoteState.SOLVED) {
            <i [ngbTooltip]="'admin.customer-note.state.SOLVED.tooltip' | translate" class="pi pi-check-circle text-green-500"></i>
          }
        </div>

        <button
          (click)="noteMenu.toggle($event)"
          pButton
          icon="pi pi-ellipsis-v"
          class="p-button-rounded p-button-text p-button-plain p-button-sm">
        </button>
        <p-menu #noteMenu [model]="noteMenuItems" [popup]="true" [baseZIndex]="99999999999" appendTo="body"></p-menu>
      </div>
    </div>
  
    @if (editing) {
      <app-si-item-note-editor [FC]="FC"></app-si-item-note-editor>

      <div class="flex justify-content-end">
        <button
          (click)="onUpdate()"
          [loading]="updating"
          pButton
          [label]="'admin.customer-note.save-btn.label' | translate"
          class="p-button-xsm mt-1">
        </button>
      </div>
    } @else {
      <p [innerHTML]="note.content" class="inner-html mb-0 text-bluegray-400"></p>
    }



  </div>
}