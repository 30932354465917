import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionBy } from 'src/app/shared/enums/utils/action-by.enum';
import { IReservation, IReservationAttendanceStats } from 'src/app/shared/models/reservation/reservation.model';
import { IReservationsSummary } from 'src/app/shared/models/reservation/reservations-summary.model';
import { CallableNames, DbService } from '../../db.service';
import { ShopItemState } from 'src/app/shared/enums/shop-item/shop-item-states.enum';
import { DataType } from 'src/app/shared/enums/collecting-data/data-type.enum';
import { IAdditionalUserDataMap, IOrgReservationFilters, IReservationsCustomDataMap } from '../../store/org-reservations-store.service';
import { IReservationDetail } from 'src/app/pages/admin/org-admin/components/modals/reservation-detail-modal/reservation-detail-modal.component';
import { ReservationState } from 'src/app/shared/enums/reservation/reservation-states.enum';
import { IUser } from 'src/app/shared/models/user/user.model';
import { OrderState } from 'src/app/shared/enums/order/order-states.enum';
import { SessionGroupCoupleRole } from 'src/app/shared/enums/session-group/session-group-couple-roles.enum';
import { IPrice } from 'src/app/shared/models/price/price.model';
import { Currency } from 'src/app/shared/enums/price/currencies.enum';
import { ICertificate } from 'src/app/shared/models/certificate/certificate.model';
import { CustomTableColumnItemGroupType, CustomTableColumnItemType, ICustomTableColumnItem, ReservationTableColumnItemGroupType } from 'src/app/pages/admin/org-admin/reservations/reservation-table/custom-table-columns-modal/custom-table-columns-modal.component';

export interface IReservationCreateData {
  email: string;
  shopItemId: number;
  name?: string;
  surname?: string;
  note: string | null;
};

export interface IReservationCancellationSummary {
  itemPrice: IPrice | null;
  totalPaid: IPrice | null;
  totalPaidPercentage: number;
  discountChange: IPrice | null;
  reservationsCnt: number;
  refund: boolean;
  orderCurrency: Currency;
}

export interface IReservationSummariesGetByUserData {
  magicCodeUuid?: string; // v pripade nereg uzivatele ( z emailu )
  userId?: number; // chci summaries tohoto uzivatele
  userTransferPG_CODE?: string; // chci summaries uzivatele ktery ma transfer s timto pg_code

  orgId?: number; // omezit se jen na danou organizaci
  orgUuid?: string; // omezit se jen na danou organizaci
  offerUuid?: string; // omezit se jen na danou offer
};

export interface IReservationGetForTableByOrgData {
  orgId: number;
  shopItemId?: number;
  eventId?: number;

  filters?: IOrgReservationFilters;

  // order by
  order?: {
    sortField: string;
    sortOrder: number;
  };

  // pagination
  limit?: number;
  offset?: number;

  // export
  export?: boolean;
  exportColumnIds?: string[];
};

export interface IReservationsCancelData {
  data: {
    reservationId: number;
    note: string | null;
    stornoFee?: number | null;
    refundToCredit: boolean;
  }[],

  sendNotifications?: boolean;
  actionBy: ActionBy;
  magicCodeUuid?: string; // customer can cancel reservation in shopping cart in webcomponent (without login)
  orderUuid?: string; // when cancelling the item from customer-order page, use this as a weak security check
  partnerReservationId?: number;
};

export interface IReservationsConfirmData {
  reservationId: number;
  note: string | null;
};

export interface IReservationsApproveWaitingData {
  reservationId: number;
  note: string | null;
};

export interface IReservationsApproveSubmittedData {
  reservationId: number;
  note: string | null;
};

export interface IReservationsSetWaitingData {
  reservationId: number;
};

export interface IReservationsConfirmAsGuestData {
  reservationId: number;
};

export interface IReservationsSetExpirationData {
  reservationId: number;
  datetime: string;
};

export interface IReservationsGetCustomData {
  reservationIds: number[];

  offerUuid?: string; // pro autentizaci usera v poptavce
}

export interface ICustomDataResult {
  id: number;
  reservationId: number;
  title: string;
  value: any;
  dataType: DataType;
  description: string;
  link?: string;
}

export interface IReservationGetForCheckinData {
  eventId: number | null;
  shopItemId: number | null;
  userId: number;
};

export interface IReservationSetCheckedinData {
  d: {
    reservationId: number;
    checkinUuid: string;
    checkedIn: boolean;
  }[]
};

export interface IGetReservationsForTableByOrgResult {
  reservations: IReservation[];
  reservationsColumnDataMap: { [key: string]: any; };
  reservationsCustomDataMap: IReservationsCustomDataMap;
  additionalUsersDataMap: IAdditionalUserDataMap;
  userIdsWithAnyCustomerNotesMap: { [key: number]: boolean };
  
  pagination: {
    limit: number;
    offset: number;
    totalCount: number;
  };
}

export interface ICallablesReservationsSendMassEmail {
  replyTo: string;
  subject: string;
  body: {
    html: string;
    text: string;
  };
  reservationIds: number[];
  orgId: number;
}

export interface IReservationGetCancellableBeforeShopItemStateChange {
  eventId?: number;
  shopItemId?: number;
};

interface IReservationGetForDetailData {
  reservationId: number
};

export interface IReservationChangeShopItemData {
  reservationId: number;
  newShopItemId: number;
  
  reservationState: ReservationState;
  daysToExpire: number;
  checkWL: boolean;
  adminNote: string | null;
  sendNotification: boolean;
  notification: { replyTo: string; subject: string; template: string } | null;
  sessionGroupCoupleRole: SessionGroupCoupleRole | null;
};

export interface IReservationsDeleteDataSysAdmin {
  reservationIds?: number[];
  userId?: number;
  organizationId?: number;
  sendNotifications: boolean;
}

export interface IReservationsDeleteData {
  reservationIds: number[];
}

export interface IReservationsGetForCustomerOrderListData {
  userId?: number;
  magicCodeUuid?: string;
  userTransferPG_CODE?: string; // chci objednavky uzivatele ktery ma transfer s timto pg_code

  // filters
  orgId?: number;
  orgUuid?: string;
  offerId?: number;
  // /
};

export interface ICallablesReservationsUpdateSimpleAdminNoteData {
  reservationId: number;
  note: string;
}

// TODO: rename
interface ICallablesReservationsTableGetCustomDataColumnsData {
  orgId: number;
  for: [CustomTableColumnItemType, CustomTableColumnItemGroupType][];
}

interface IReservationsGetForCertificateIssueModalData {
  shopItemId: number;
}
type IReservationsGetForCertificateIssueModalResponse = { reservation: IReservation& { certificate: ICertificate | null, beneficiaryUser: IUser }, attendanceStats: IReservationAttendanceStats }[];


@Injectable({
  providedIn: 'root'
})
export class ReservationsService {

  constructor(
    private dbService: DbService,
  ) { }

  public getForCertificateIssueModal(data: IReservationsGetForCertificateIssueModalData): Observable<IReservationsGetForCertificateIssueModalResponse> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsGetForCertificateIssueModal, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getForCustomerOrderList(data: IReservationsGetForCustomerOrderListData, allSilent?: boolean): Observable<IReservation[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsGetForCustomerOrderList, data });
    return this.dbService.handleObs(obs, { succSilent: true, allSilent});
  }

  // pozor velmi citlive
  public deleteReservatonsSysAdmin(data: IReservationsDeleteDataSysAdmin) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsDeleteSysAdmin, data });
    return this.dbService.handleObs(obs);
  }

  public deleteReservatons(data: IReservationsDeleteData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsDelete, data });
    return this.dbService.handleObs(obs);
  }

  public changeShopItem(data: IReservationChangeShopItemData): Observable<number> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsChangeShopItem, data });
    return this.dbService.handleObs(obs);
  }

  public getForDetail(data: IReservationGetForDetailData): Observable<IReservationDetail> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsGetForDetail, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getCancellableBeforeShopItemStateChange(data: IReservationGetCancellableBeforeShopItemStateChange): Observable<IReservation[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsGetCancellableBeforeShopItemStateChange, data });
    return this.dbService.handleObs(obs, { succSilent: true});
  }

  public sendMassMails(data: ICallablesReservationsSendMassEmail) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsSendMassEmail, data });
    return this.dbService.handleObs(obs);
  }

  public setCheckedin(data: IReservationSetCheckedinData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsSetCheckedin, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getForCheckin(data: IReservationGetForCheckinData): Observable<{ user: IUser, confirmedReservations: IReservation[] }> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsGetForCheckin, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getForTableByOrg(data: IReservationGetForTableByOrgData): Observable<IGetReservationsForTableByOrgResult | string> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsGetForTableByOrg, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getSummariesByUser(data: IReservationSummariesGetByUserData, allSilent?: boolean): Observable<IReservationsSummary[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsGetUserSummaries, data });
    return this.dbService.handleObs(obs, { succSilent: true, allSilent });
  }

  public confirm(data: IReservationsConfirmData[]) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsConfirm, data });
    return this.dbService.handleObs(obs);
  }

  public approveWaiting(data: IReservationsApproveWaitingData[]) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsApproveWaiting, data });
    return this.dbService.handleObs(obs);
  }

  public approveSubmitted(data: IReservationsApproveSubmittedData[]) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsApproveSubmitted, data });
    return this.dbService.handleObs(obs);
  }

  public cancel(data: IReservationsCancelData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsCancel, data });
    return this.dbService.handleObs(obs);
  }

  public setWaiting(data: IReservationsSetWaitingData[]) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsSetWaiting, data });
    return this.dbService.handleObs(obs);
  }

  public confirmAsGuest(data: IReservationsConfirmAsGuestData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsConfirmAsGuest, data });
    return this.dbService.handleObs(obs);
  }

  public setExpiration(data: IReservationsSetExpirationData[]) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsSetExpiration, data });
    return this.dbService.handleObs(obs);
  }

  public getCustomData(data: IReservationsGetCustomData): Observable<ICustomDataResult[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsGetCustomData, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public restoreCancelled(reservationId: number, onlyIndividual: boolean): Observable<IReservation> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsRestoreCancelled, data: { reservationId, onlyIndividual } });
    return this.dbService.handleObs(obs);
  }

  public getCancellationSummary(reservationId: number): Observable<IReservationCancellationSummary> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsGetCancellationSummary, data: { reservationId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getCollectedUserDataColumns(data: ICallablesReservationsTableGetCustomDataColumnsData): Observable<{ [groupType in CustomTableColumnItemGroupType]?: ICustomTableColumnItem[]; }> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsTableGetCustomDataColumns, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public updateSimpleAdminNote(data: ICallablesReservationsUpdateSimpleAdminNoteData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsUpdateSimpleAdminNote, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  // temp only for testing
  public create(data: IReservationCreateData[]) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesReservationsCreate, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
